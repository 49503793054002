import { ReactNode, useMemo, useState } from 'react';
import { format } from 'date-fns/esm';
import { __ } from 'i18n/translate';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { socketConnection } from 'services/socket';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this does not have types :/
import useSound from 'use-sound';
import alertSound from '../../assets/sound.mp3';
import { Ticket, User, WebsocketMessage } from 'types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { NotificationContext } from './NotificationContext';

type Props = {
    user: User;
    children: ReactNode;
};

const NotificationsProvider = ({ user, children }: Props) => {
    const navigate = useNavigate();

    const {
        profile,
        queues,
        companyDistribuitionMethod,
        id: userId,
        companyId
    } = user;
    const queuesIds = useMemo(() => queues.map((a) => a.id), [queues]);
    const isAdmin = profile === 'admin';
    const [play] = useSound(alertSound);
    const [userSoundEnabledFromStorage] = useLocalStorage(
        'userSoundEnabled',
        true
    );
    const [userPopupEnabledFromStorage] = useLocalStorage(
        'userPopupEnabled',
        true
    );
    const [userNotificationSoundEnabled, setUserNotificationSoundEnabled] =
        useState(userSoundEnabledFromStorage);
    const [userNotificationEnabled, setUserNotificationEnabled] = useState(
        userPopupEnabledFromStorage
    );

    // console.log('userNotificationEnabled', userNotificationEnabled);
    // console.log('userNotificationSoundEnabled', userNotificationSoundEnabled);
    // console.log('userSoundEnabledFromStorage', userSoundEnabledFromStorage);
    // console.log('userPopupEnabledFromStorage', userPopupEnabledFromStorage);

    useEffect(() => {
        if (!('Notification' in window)) {
            console.log("This browser doesn't support notifications");
        } else {
            Notification.requestPermission();
        }
    }, []);

    useEffect(() => {
        if (!userNotificationEnabled) {
            return;
        }
        const socket = socketConnection({ companyId });

        socket.on('connect', () => socket.emit('joinNotification'));

        const shouldUpdateTicket = (ticket: Ticket) => {
            //usuario está logado ou ticket não é objeto valido
            if (!userId || !ticket || !ticket.id) {
                return false;
            }

            if (isAdmin) {
                //usuário admin
                if (
                    (ticket.queueId &&
                        queuesIds.indexOf(ticket.queueId) > -1) ||
                    !ticket.queueId
                ) {
                    return true;
                }
            } else {
                //usuário normal
                if (ticket.chatbot || !ticket.queueId) {
                    return false;
                }
                if (ticket.status === 'open') {
                    return (
                        queuesIds.indexOf(ticket.queueId) > -1 &&
                        ticket.userId === userId
                    );
                } else if (ticket.status === 'pending') {
                    if (companyDistribuitionMethod === 'manual') {
                        return (
                            queuesIds.indexOf(ticket.queueId) > -1 &&
                            (!ticket.userId || ticket.userId === userId)
                        );
                    } else {
                        return ticket.userId === userId;
                    }
                } else if (ticket.status === 'closed') {
                    return ticket.userIdClosed === userId;
                }
            }
        };

        const handleMessage = (data: WebsocketMessage) => {
            console.log('data', data);
            if (
                data.action === 'create' &&
                !data.message.read &&
                shouldUpdateTicket(data.ticket) &&
                !data.ticket.chatbot &&
                (data.ticket.userId === userId || !data.ticket.userId)
            ) {
                const { message, contact, ticket } = data;

                const options = {
                    body: `${message.body} - ${format(new Date(), 'HH:mm')}`,
                    icon:
                        !contact.profilePicUrl ||
                        contact.profilePicUrl.indexOf('nopicture') > -1
                            ? 'https://selene-producao.s3.sa-east-1.amazonaws.com/logo.png'
                            : contact.profilePicUrl
                };

                console.log('WILL send notification', options);

                const notification = new Notification(
                    __('tickets.messageFrom', { name: contact.name }),
                    options
                );
                notification.addEventListener('click', () => {
                    console.log(
                        'clicked notification',
                        `/tickets?ticket=${ticket.uuid}`
                    );
                    window.focus();
                    navigate(`/tickets?ticket=${ticket.uuid}`);
                });

                if (userNotificationSoundEnabled) {
                    play();
                }
            }
        };

        socket.on(`company-${companyId}-appMessage`, handleMessage);

        return () => {
            socket.off(`company-${companyId}-appMessage`, handleMessage);
            socket.disconnect();
        };
    }, [
        userId,
        companyId,
        userNotificationEnabled,
        userNotificationSoundEnabled,
        navigate,
        play,
        isAdmin,
        queuesIds,
        companyDistribuitionMethod
    ]);

    return (
        <NotificationContext.Provider
            value={{
                userNotificationEnabled,
                userNotificationSoundEnabled,
                setUserNotificationEnabled,
                setUserNotificationSoundEnabled
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationsProvider;
