import api from '../services/api';

export const getLeedsInAutomationReport = (): Promise<string> => {
    return api.post(`/report/leadsInAutomation`, {}).then(({ data }) => data);
};

export const getLeadsPerDayReport = (
    dateFrom: string,
    dateTo: string,
    userId: number | null
): Promise<string> => {
    return api
        .post(`/report/leadsUserPerDay`, { dateFrom, dateTo, userId })
        .then(({ data }) => data);
};

export const getLeadsAwaitingReport = (
    dateFrom: string,
    dateTo: string
): Promise<string> => {
    return api
        .post(`/report/leadsTotalAwaiting`, { dateFrom, dateTo })
        .then(({ data }) => data);
};
