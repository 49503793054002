import {
    Button,
    DateRangeField,
    Form,
    FormFooter,
    FormSection,
    Page,
    toast
} from '@7shifts/sous-chef';
import { getLeadsAwaitingReport } from 'api/reportsApi';
import { useFormik } from 'formik';
import { __ } from 'i18n/translate';
import { downloadCSV } from 'utils/csv';
import { dateYearMonthDay } from 'utils/date';

type FormData = {
    date: { start: Date; end: Date };
};

const LeedsAwaitingPage = () => {
    const formik = useFormik<FormData>({
        initialValues: {
            date: {
                start: new Date(),
                end: new Date()
            }
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            getLeadsAwaitingReport(
                dateYearMonthDay(values.date.start),
                dateYearMonthDay(values.date.end)
            )
                .then((data) => {
                    downloadCSV(data, 'leads-average-awaiting.csv');
                })
                .catch(() => {
                    toast('Something went wrong', 'danger');
                })
                .finally(() => formik.setSubmitting(false));
        }
    });

    return (
        <Page
            breadcrumbs={__('main.reports')}
            title={__('reportsPage.reportLeadsAwaiting')}
            subtitle={__('reportsPage.reportLeadsAwaitingDescription')}
        >
            <Form formik={formik}>
                <FormSection as="card">
                    <DateRangeField
                        name="date"
                        label="Período"
                        format="dd/MM/yyyy"
                    />
                </FormSection>
                <FormFooter
                    actions={{
                        primary: (
                            <Button type="submit" loading={formik.isSubmitting}>
                                {__('main.downloadCSV')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Page>
    );
};

export default LeedsAwaitingPage;
