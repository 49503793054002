import {
    IconTachometer,
    IconMessages,
    IconPaperPlane,
    IconUserFriends,
    IconCalendarTomorrow,
    IconFlag,
    IconRepeat,
    IconCog,
    IconFourDotsCircle,
    IconUsers,
    IconUserComputer,
    IconCommentLines,
    IconMoneyBill
} from '@7shifts/sous-chef';
import { NavBarItems } from 'app-layout-frame/dist/layout/types';
import { __ } from 'i18n';
import { User, WhatsApp } from 'types';

export const getNavItems = (user: User, whatsApps: WhatsApp[]): NavBarItems => {
    const nonConnectedChannel = whatsApps.find(
        (item) => item.status !== 'CONNECTED'
    );
    const isAdmin = user.profile === 'admin';
    const isGrupoAcelerador = user.companyId === 19; // solução imediata
    const isHelisul = user.companyId === 18; // solução imediata

    return [
        {
            label: __('main.dashboard'),
            isActive: true,
            isShowing: true,
            url: '/dashboard',
            icon: IconTachometer
        },
        {
            label: __('main.tickets'),
            isActive: false,
            isShowing: true,
            url: '/tickets',
            icon: IconMessages
        },
        {
            label: __('main.modelMessages'),
            isActive: false,
            isShowing: !!whatsApps.find(
                (channel) => channel.tokenMeta !== null
            ),
            url: '/message-templates',
            icon: IconCommentLines
        },
        {
            label: __('main.quickMessages'),
            isActive: false,
            isShowing: true,
            url: '/quick-messages',
            icon: IconPaperPlane
        },
        {
            label: __('main.contacts'),
            isActive: false,
            isShowing: true,
            url: '/contacts',
            icon: IconUserFriends
        },
        {
            label: __('main.schedules'),
            isActive: false,
            isShowing: true,
            url: '/schedules',
            icon: IconCalendarTomorrow
        },
        {
            label: __('main.tags'),
            isActive: false,
            isShowing: true,
            url: '/tags',
            icon: IconFlag
        },
        {
            type: 'divider'
        },
        {
            label: __('main.templateInfoContact'),
            isActive: false,
            isShowing: isAdmin,
            url: '/templates',
            icon: IconUserComputer
        },
        {
            label: __('main.reports'),
            isActive: false,
            isShowing: isAdmin && (isGrupoAcelerador || isHelisul),
            url: '/reports',
            icon: IconTachometer
        },
        {
            label: __('main.channels'),
            isActive: false,
            isShowing: isAdmin,
            url: '/channels',
            icon: IconFourDotsCircle,
            badge:
                whatsApps.length === 0 || nonConnectedChannel
                    ? 'Missing'
                    : undefined
        },
        {
            label: __('main.queues'),
            isActive: false,
            isShowing: isAdmin,
            url: '/queues',
            icon: IconRepeat
        },
        {
            label: __('main.users'),
            isActive: false,
            isShowing: isAdmin,
            url: '/users',
            icon: IconUsers
        },
        {
            label: __('main.invoices'),
            isActive: false,
            isShowing: isAdmin,
            url: '/invoices',
            icon: IconMoneyBill
        },
        {
            label: __('main.settings'),
            isActive: false,
            isShowing: isAdmin,
            url: '/settings',
            icon: IconCog
        }
    ];
};
